import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getInstitutionDetails(id) {
    return await axiosInstance
        .get(`tracker/reports/institution/articles?query=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionJournals(id) {
    return await axiosInstance
        .get(`tracker/reports/articles/journals?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionsPercentage() {
    return await axiosInstance
        .get(`tracker/reports/articles/identifiers`)
        .catch(handleErrors)
};

export async function getInstitutionArticlesPerMonth(id) {
    return await axiosInstance
        .get(`tracker/reports/lastyear/articles?ror=${id}`)
        .catch(handleErrors)
};
