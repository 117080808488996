import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { getInstitutionDetails, getInstitutionJournals, getInstitutionArticlesPerMonth } from "../../helpers/institutions";
import {
    Table,
    Loader,
    ErrorMessage,
    AsyncInput,
    Card,
    BarChart,
    PieChart,
    ArticleDetailsModal,
    loadRorOptions,
} from "pubtrack-frontend-library";
import { MDBCol, MDBRow } from 'mdbreact';
import { getArticleDetails } from "../../helpers/articles";
import { userSettings } from "../../user-settings";


const Institutions = () => {
    const [institutionDetails, setInstitutionDetails] = useState(null);
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [institutionArticlesPerMonth, setInstitutionArticlesPerMonth] = useState(null);
    const [institutionJournals, setInstitutionJournals] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const institutionDetailsColumns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Journal',
                accessor: 'journal',
            },
            {
                Header: 'DOI',
                accessor: 'doi',
            },
        ], []
    );

    useEffect(() => {
        if (value) {
            let isMounted = true;
            setLoading(true);

            Promise.all([
                getInstitutionArticlesPerMonth(encodeURIComponent(value))
                    .then(res => setInstitutionArticlesPerMonth(res.data)),

                getInstitutionJournals(encodeURIComponent(value))
                    .then(res => setInstitutionJournals(res.data)),

                getInstitutionDetails(encodeURIComponent(value))
                    .then(res => setInstitutionDetails(res.data))
            ])
                .then(res => {
                    if (isMounted) {
                        setLoading(false);
                    }
                })
                .catch(error => setError(true));
            return () => { isMounted = false };
        }
    }, [value]);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };


    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <h5 className="text-center my-4">Institution search:</h5>
                <div className="d-flex justify-content-end mb-4">
                    <Field component={AsyncInput} loadOptions={loadRorOptions} setValue={setValue} placeholder="Search" />
                </div>

                {
                    loading ?
                        <Loader />
                        :
                        error ?
                            <ErrorMessage />
                            :
                            <>
                                {
                                    institutionArticlesPerMonth && institutionJournals &&
                                    <MDBRow className="mt-4">
                                        <MDBCol md='7' className="mb-5 mt-4">
                                            <Card
                                                title="Institution articles per month:"
                                                content={<BarChart data={institutionArticlesPerMonth} xAxisKey="month" yAxisKey="count" />}
                                            />
                                        </MDBCol>
                                        <MDBCol md='5' className="mb-5 mt-4">
                                            <Card
                                                title="Institution journals:"
                                                content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }

                                {
                                    !institutionDetails ?
                                        null
                                        :
                                        <div className="mb-4">
                                            <Table
                                                title="Institution articles:"
                                                data={institutionDetails}
                                                columns={institutionDetailsColumns}
                                                sortBy={sortBy}
                                                sortDir={sortDir}
                                                setSortBy={setSortBy}
                                                setSortDir={setSortDir}
                                                onSelectRow={onSelectRow}
                                            />
                                        </div>
                                }
                            </>
                }

                <ArticleDetailsModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    articleDetails={articleDetails}
                    tabItems={userSettings.details}
                />
            </div>
        </div>
    );
};


export default withFormik({})(Institutions);